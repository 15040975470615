




















import Vue from "vue";
import { BFormInput } from "bootstrap-vue";

export default Vue.extend({
  name: "RangeInput",
  props: {
    value: {
      type: [String, Number],
      required: true
    },
    max: {
      type: Number,
      default: 5000
    },
    min: {
      type: Number,
      default: 0
    },
    step: {
      type: Number,
      default: 500
    }
  },
  data() {
    return {
      localValue: ""
    };
  },
  mounted() {
    this.updateThumbLabelPosition();
  },
  watch: {
    value: {
      handler: function(val: string | number) {
        this.localValue = `${val}`;
        this.updateThumbLabelPosition();
      },
      immediate: true
    }
  },
  methods: {
    updateThumbLabelPosition() {
      const range = this.$refs.thumb as BFormInput;
      const thumbLabel = this.$refs.thumbLabel as HTMLElement;
      if (range) {
        const thumbSize = 15;
        const ratio = (+this.value - range.min) / (range.max - range.min);
        thumbLabel.style.left = `calc(${ratio * 100}% + ${(thumbSize *
          (1 - ratio)) /
          2}px`;
      }
    },
    nextStep(i: number): number {
      return (
        Math.floor(this.min / this.step) * this.step +
        ((this.min % this.step === 0 ? 0 : 1) + i) * this.step
      );
    },
    setField(value: string) {
      this.localValue = value;
      this.$emit("input", +value);
    }
  }
});
