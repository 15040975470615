






























import Vue, { PropType } from "vue";
import FieldGroup from "@/components/form/FieldGroup.vue";
import { Validation } from "vuelidate";

export default Vue.extend({
  name: "FormField",
  components: { FieldGroup },
  props: {
    value: {
      type: [String, Number]
    },
    v: {
      type: Object as PropType<Validation>
    },
    id: {
      type: String,
      required: true
    },
    label: {
      type: String
    },
    placeholder: {
      type: String
    },
    purpleLabel: {
      type: Boolean,
      default: false
    },
    autofocus: {
      type: Boolean,
      default: false
    },
    formatter: {
      type: Function as PropType<(value: string | number) => string>,
      default: function(value) {
        const parsedValue = this.parser(value);
        return new Intl.NumberFormat("en-IN").format(parsedValue);
      }
    },
    parser: {
      type: Function as PropType<(value: string | number) => number>,
      default: value => {
        const cleanValue = parseFloat(`${value}`.replaceAll(/\D/g, ""));
        return isNaN(cleanValue) ? 0 : cleanValue;
      }
    }
  },
  computed: {
    state(): boolean {
      return this.v && this.v.$dirty && this.v.$error;
    },
    isValidNumber(): boolean {
      return !!`${this.value}`.match(/[\d.,]/);
    }
  },
  created() {
    this.tmp = this.formatter(this.value);
  },
  watch: {
    value(val) {
      this.tmp = val;
    }
  },
  data() {
    return {
      tmp: ""
    };
  },
  methods: {
    setField(value: string) {
      this.tmp = `${this.parser(value)}`;
      this.$emit("input", this.parser(value));
      if (this.v) {
        this.v.$reset();
      }
    }
  }
});
