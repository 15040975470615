




















import { PropType } from "vue";
import { errorToast, successToast } from "@/toast";
import mixins from "vue-typed-mixins";
import formMixin from "@/mixins/form";
import { Validation } from "vuelidate";

export default mixins(formMixin).extend({
  name: "Form",
  props: {
    id: {
      type: String,
      default: "form"
    },
    v: {
      type: Object as PropType<Validation>,
      required: true
    },
    onSubmit: {
      type: Function,
      required: true
    },
    fullWidth: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    errorToast,
    successToast,
    async submit() {
      // @ts-ignore
      this.validate(this.v);
      if (!this.v.$anyError) {
        try {
          await this.onSubmit();
          this.successToast(
            this,
            "Your modifications were successfully saved."
          );
        } catch (e) {
          console.error("Form submission failed: ", e);
          this.errorToast(this, "Form submission failed");
        } finally {
          window.scrollTo({ top: 0, behavior: "smooth" });
        }
      } else {
        this.errorToast(
          this,
          "The form has errors, please correct them to save your data."
        );
      }
    }
  }
});
