







































































































































































































import LayoutWithWsLogo from "@/components/layout/LayoutWithWsLogo.vue";
import Avatar from "@/components/avatar/Avatar.vue";
import Form from "@/components/form/Form.vue";
import RangeInput from "@/components/form/RangeInput.vue";
import NumberInput from "@/components/form/NumberInput.vue";
import { mapState } from "vuex";
import workspaceMixin from "@/mixins/workspace";
import injectState from "@/mixins/injectState";
import mixins from "vue-typed-mixins";
import { WorkspaceState } from "@/store/modules/workspace";
import workspaceStore from "@/store/modules/workspace";
import {
  Maybe,
  Plan,
  Subscription,
  SubscriptionSettingsInput
} from "@/generated/graphql";
import { ActionTypes } from "@/store/action-types";
import { EnhancedPlan, toEnhancedPlan } from "@/models/organization";
import { isValidNumber } from "@/validation";
import { BIconExclamationTriangleFill } from "bootstrap-vue";

type Form = {
  input: SubscriptionSettingsInput;
  priceId: string | null;
};
const defaultForm: Form = {
  input: {
    creditUsageLimit: 0,
    creditUsageNotification: 0
  },
  priceId: null
};
export default mixins(
  workspaceMixin,
  injectState<WorkspaceState, Form>(
    workspaceStore.state,
    defaultForm,
    state => {
      if (state.currentWorkspaceSubscription) {
        const { settings, plan } = state.currentWorkspaceSubscription;
        return {
          input: { ...settings },
          priceId: plan ? plan.id : null
        };
      } else {
        return null;
      }
    }
  )
).extend({
  components: {
    LayoutWithWsLogo,
    Form,
    RangeInput,
    NumberInput,
    Avatar,
    BIconExclamationTriangleFill
  },
  validations: {
    form: {
      input: {
        creditUsageNotification: {
          isValidNumber
        },
        creditUsageLimit: {
          isValidNumber
        }
      }
    }
  },
  data() {
    return {
      isCreditUsageNotificationEnabled: false,
      isCreditUsageLimitEnabled: false,
      eligiblePlanIds: null as string[] | null
    };
  },
  created() {
    this.$storeTyped.dispatch(ActionTypes.FETCH_PLANS);
    this.$storeTyped
      .dispatch(ActionTypes.FETCH_CURRENT_WORKSPACE_ELIGIBLE_PLANS, this.wsKey)
      .then((ids: string[]) => (this.eligiblePlanIds = ids));
  },
  watch: {
    wsKey: {
      handler(id: string) {
        this.$storeTyped
          .dispatch(ActionTypes.FETCH_CURRENT_WORKSPACE_SUBSCRIPTION, id)
          .then((newSub: Maybe<Subscription>) => {
            this.form.priceId = newSub?.plan.id || null;
            if (newSub && newSub.settings) {
              this.isCreditUsageNotificationEnabled =
                newSub.settings.creditUsageNotification > 0;
              this.isCreditUsageLimitEnabled =
                newSub.settings.creditUsageLimit > 0;
            } else {
              this.isCreditUsageNotificationEnabled = false;
              this.isCreditUsageLimitEnabled = false;
            }
          });
      },
      immediate: true
    }
  },
  computed: {
    ...mapState({
      currentSubscription: state => state.workspace.currentWorkspaceSubscription
    }),
    plans(): EnhancedPlan[] {
      return this.$storeTyped.getters.getPlans;
    },
    priceId(): string | null {
      return this.form.priceId;
    },
    currentPlan(): EnhancedPlan | null {
      return (
        (this.plans && this.plans.find(p => p.id === this.priceId)) || null
      );
    },
    creditUsageNotification(): number {
      return this.form.input.creditUsageNotification;
    },
    creditLimit(): number {
      return (
        +`${this.form.input.creditUsageLimit}`.replace(/,/g, "") + 0 //this.form.stripe.price.creditLimit
      );
    },
    planTableHeaderStyles(): Partial<CSSStyleDeclaration> {
      return {
        width: `${Math.floor(100 / (this.plans.length + 1))}%`
      };
    }
  },
  methods: {
    setCreditUsageLimit(nb: string) {
      this.form.input.creditUsageLimit = +nb;
    },
    selectPlan(plan: Plan) {
      this.form = {
        ...this.form,
        priceId: plan.id
      };
    },
    async onSubmit() {
      const { input, priceId } = this.form;
      await this.$storeTyped.dispatch(
        ActionTypes.UPDATE_CURRENT_WORKSPACE_PLAN,
        {
          ...input,
          priceId
        }
      );
    },
    isCurrentPlan(plan: EnhancedPlan) {
      return plan.id === this.priceId;
    },
    getPlanSelectionLabel(plan: EnhancedPlan) {
      const persistedPlan = this.currentSubscription?.plan
        ? toEnhancedPlan(this.currentSubscription.plan)
        : null;
      if (persistedPlan === null) {
        return "Choose this plan";
      } else if (persistedPlan.id === plan.id) {
        return "Current";
      } else if (persistedPlan.flatAmount > plan.flatAmount) {
        return "Downgrade";
      } else {
        return "Upgrade";
      }
    },
    toggleCreditUsageNotification() {
      this.isCreditUsageNotificationEnabled = !this
        .isCreditUsageNotificationEnabled;
      if (this.form.input) {
        this.form.input.creditUsageNotification = this
          .isCreditUsageNotificationEnabled
          ? 1000
          : 0;
      }
    },
    toggleCreditUsageLimit() {
      this.isCreditUsageLimitEnabled = !this.isCreditUsageLimitEnabled;
      this.form.input.creditUsageLimit = this.isCreditUsageLimitEnabled
        ? 1000
        : 0;
    },
    isNonEligible(plan: EnhancedPlan): boolean {
      return (
        Boolean(this.eligiblePlanIds) &&
        Boolean(plan) &&
        !this.eligiblePlanIds?.includes(plan.id)
      );
    }
  },
  filters: {
    switchLabel(value: boolean) {
      return value ? "Active" : "Disabled";
    }
  }
});
